export const firebaseConfig = {
  apiKey: "AIzaSyAbgdWzNeBVYEbI5K9Rbfw-fu-BZoYoYjU",
  authDomain: "ydtechs-3cdfb.firebaseapp.com",
  projectId: "ydtechs-3cdfb",
  storageBucket: "ydtechs-3cdfb.appspot.com",
  messagingSenderId: "423038934447",
  appId: "1:423038934447:web:b7b1f6f81f986a1872e35a",
  measurementId: "G-6Z8Y5LC4KM",
  databaseURL: "https://ydtechs-3cdfb-default-rtdb.firebaseio.com/",
};
export default firebaseConfig;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import Admin from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { firebaseConfig } from "config";

export const initializeFirebaseConfig = firebase.initializeApp(firebaseConfig);
//const user = firebase.auth().currentUser;
//console.log(localStorage.getItem('token'))
var uid = null;
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    uid = user.l;
    //console.log(user)
    //console.log(user.l)

    // render dashboard route if user is login 
    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          {uid !== null ?
            <>
              <Route path="/admin/*" render={(props) => <Admin {...props} />} />
              <Redirect from="/" to="/admin/dashboard" />
            </>
            : null}
        </Switch>
      </BrowserRouter>,
      document.getElementById("root")
    );
  } else {
    uid = null;

    // render home page, login route if user is sign out
    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Route path="/auth/login" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>,
      document.getElementById("root")
    );
  }
});


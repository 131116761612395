
function test() {

}
export default test();
//Note change last four character for 
// 1. testing of the application by "Test" 
// 2. production of the application by "Prod"
export const CustomersInfo = 'CustomerInfo';
export const databaseOrder = 'Order';

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Orders from "views/adminUI/Orders";
import Dashboard from "views/adminUI/Dashboard.js";
import Customers from "views/adminUI/Customers";
import SupportTicket from "views/adminUI/SupportTicket";
export const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    miniName: "D",
    icon: "ni ni-shop",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    miniName: "A",
    icon: "fa fa-shopping-basket",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    miniName: "A",
    icon: "fa fa-users",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/supportticket",
    name: "Support Ticket",
    miniName: "A",
    icon: "fa fa-search",
    component: SupportTicket,
    layout: "/admin",
  }
];

export default routes;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import {
    Container, CardBody
} from 'reactstrap';
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import MaterialTable from "material-table";
import { tableIcons } from './CustomComponent/reuseableFn';
import { databaseOrder } from "./CustomComponent/BackendDataConfig"


function SupportTicket() {

    const [orderHistory, setOrderHistory] = React.useState(null);
    const [columnTabcStampHistory] = useState([
        { title: 'Invoice No', field: 'OrderNumber' },
        { title: 'Invocie Date', field: 'invoiceDate' },
        { title: 'TABC Stamp No', field: 'tabcStickerRange' },
        { title: 'Stamp Total Used', field: 'tabcStickerUsed', type: 'numeric' },
        { title: 'Business DBA', field: 'businessdbaname' },
        { title: 'Corporation Name', field: 'corporationname' },

    ]);
    //initialize to get data of all customers, order history
    useEffect(() => {
        const dbRef = firebase.database().ref(databaseOrder);
        dbRef.get().then((snapshot) => {
            let users = []
            if (snapshot.exists()) {
                snapshot.forEach(snap => {
                    users.push(snap.val())
                })
                setOrderHistory(users)
                console.log(users)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    },[]);

    return (
        <>
              <br/><br/><br/><br/>
            <Container className="mt--6" fluid>
                <CardBody>
                    {orderHistory !== null ?
                        <>
                            <MaterialTable
                                title="TABC Sticker Record"
                                icons={tableIcons}
                                columns={columnTabcStampHistory}
                                data={orderHistory}
                                options={{
                                    exportButton: true,
                                    search: true,
                                    headerStyle: {
                                        backgroundColor: '#8BCCFE',
                                        color: '#000000',
                                        textColor: '#000000',
                                    }
                                }}
                            />
                        </>
                        : null}
                </CardBody>
            </Container>
        </>
    );
}

export default SupportTicket;
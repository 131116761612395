/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Card, Container } from "reactstrap";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { CustomersInfo } from "./CustomComponent/BackendDataConfig";
import MaterialTable from "material-table";
import { tableIcons } from "./CustomComponent/reuseableFn";

function Customers() {
  const [allCustomerDetails, setAllCustomerDetails] = React.useState([]);
  useEffect(() => {
    const dbRef = firebase.database().ref(CustomersInfo);
    dbRef
      .get()
      .then((snapshot) => {
        let users = [];
        if (snapshot.exists()) {
          //console.log(snapshot)
          snapshot.forEach((snap) => {
            users.push(snap.val());
          });
          if (users[0] !== undefined) {
            setAllCustomerDetails(users[0]);
            console.log(users);
          }
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [columnOptionData] = React.useState([
    { title: "Corporation Name", field: "CorporationName" },
    { title: "Business DBA", field: "BusinessDBA" },
    { title: "Phone Number", field: "PhoneNumber", type: "numeric" },
    { title: "Email", field: "Email", type: "email" },
    { title: "Owner Name", field: "OwnerName" },
    {
      title: "Application Type",
      field: "ApplicationType",
      lookup: {
        WebDesign: "Web Design",
        MobileApp: "Mobile App",
        CustomSoftware: "Custom Software",
        ITConsultant: "IT Consultant",
        BookingWebsite: "Booking Website",
        ECommerce: "E-Commerce",
        TaskAutomation: "Task Automation",
        HostedEmailService: "Hosted Email Service",
      },
    },
    {
      title: "Status",
      field: "Status",
      lookup: { Active: "Active", NOTActive: "NOT Active" },
    },
    { title: "Address", field: "Address" },
    { title: "City", field: "City" },
    { title: "State", field: "State" },
    { title: "Zip", field: "Zip", type: "numeric" },
  ]);

  if (allCustomerDetails[0] !== undefined) {
    firebase.database().ref(CustomersInfo).set({
      Users: allCustomerDetails,
    });
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <Container className="mt--6" fluid>
        <Card>
          <MaterialTable
            title="Customer List"
            icons={tableIcons}
            columns={columnOptionData}
            data={allCustomerDetails}
            options={{
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                if (rowData.Status === "Active") {
                  return { backgroundColor: "#c4ffd6" };
                } else if (rowData.Status === "NOTActive") {
                  return { backgroundColor: "#ff9c9c" };
                } else {
                  return { backgroundColor: "#ffffff" };
                }
              },
              headerStyle: {
                backgroundColor: "#8BCCFE",
                color: "#000000",
                textColor: "#000000",
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setAllCustomerDetails([...allCustomerDetails, newData]);

                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...allCustomerDetails];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setAllCustomerDetails([...dataUpdate]);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...allCustomerDetails];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setAllCustomerDetails([...dataDelete]);

                    resolve();
                  }, 1000);
                }),
            }}
          />
        </Card>
      </Container>
    </>
  );
}

export default Customers;

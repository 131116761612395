/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AuthHeader({ title, lead }) {
  return (
    <>
    
        <Container >
          <div className="header-body text-center">
          {<img
              alt="..."
              width="200"
              display="block"
              height="100%"
              src={require("assets/img/brand/YDTechsLogo.png").default}
            />
            }
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5" >
                {title ? <h1 className="text-black" >{title}</h1> : null}
                {lead ? <h3 className="text-lead text-black" >{lead}</h3> : null}
                <br/>
              </Col>
            </Row>
          </div>
        </Container>
        
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import { makeStyles } from "@material-ui/core/styles";
import {
  Col,
  Row,
  Form,
  Container,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
// core components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import firebase from "firebase/app";
import "firebase/auth";
import {
  CustomersInfo,
  databaseOrder,
} from "./CustomComponent/BackendDataConfig";
import "firebase/firestore";
import "firebase/database";
import NumberFormat from "react-number-format";
import MaterialTable from "material-table";
import { tableIcons } from "./CustomComponent/reuseableFn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    background: "linear-gradient(45deg, #5AB4FB 30%, #289FFC 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(4, 116, 204, .3)",
  },
  label: {
    textTransform: "capitalize",
  },
}));
export function Orders() {
  const [showNewOrderForm, setShowNewOrderForm] = React.useState(false);
  const [orderHistory, setOrderHistory] = React.useState(null);
  const [allCustomerDetails, setAllCustomerDetails] = React.useState({});
  const [searchCustomerID, setSearchCustomerID] = React.useState({});
  const [getSelectedCustAllData, setGetSelectedCustAllData] = React.useState(null);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [openDialogOrderDetails, setOpenDialogOrderDetails] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState({});
  const classes = useStyles();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xl");
  // column to show on invoice table and create row radomly

  const [columnsCreateOrder] = useState([
    { title: "Product Name", field: "itemname" },
    { title: "Size", field: "size", lookup: { "750ml": "750ml", "1L": "1L", "1.75L": "1.75L" }, },
    { title: "Quantity", field: "quantity", type: "numeric" },
    { title: "Cost Per Unit", field: "costperunit", type: "currency" },
    {
      title: "Total Cost", field: "totalcost", type: "currency", editable: "false",
      render: (rowData) => {
        return rowData.costperunit * rowData.quantity;
      },
    },
  ]);
  const [columnsOrderHistory] = useState([
    { title: "Invoice Number", field: "OrderNumber" },
    { title: "Invoice Date", field: "invoiceDate" },
    { title: "Total Cost", field: "totalCost", type: "currency" },
    { title: "Business Name", field: "businessdbaname" },
  ]);
  // calculated value to show order short information
  let orderTotal = 0;

  if (getSelectedCustAllData !== null) {
    orderTotal = invoiceData.reduce((a, b) => a + b.totalcost, 0);

  }
  //initialize to get data of all customers, order history
  useEffect(() => {
    const dbRefOrder = firebase.database().ref(databaseOrder);
    const dbRefCustomer = firebase.database().ref(CustomersInfo);

    dbRefCustomer.get().then((snapshot) => {
      let users = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users.push(snap.val());
        });
        if (users[0] !== undefined) {
          //console.log('Customer \n'+ snapshot);
          setAllCustomerDetails(users[0]);
          setSearchCustomerID(users[0].map((a) => a.Email)); // will create a drop down list with email address
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

    dbRefOrder.get().then((snapshot) => {
      let users1 = [];
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          users1.push(snap.val());
        });
        setOrderHistory(users1);
        //console.log('order: ' + users1);
      } else {
        console.log("No data available");
      }
    })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //send data to database and create invoice for customers
  const handleOrderForm = (event) => {
    event.preventDefault();
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    // generate unique invoice number
    let uniqueNumber =
      month + "" + date + "" + year + "" + hours + "" + minutes;

    // console.log(uniqueNumber)
    firebase.database().ref(databaseOrder + "/" + uniqueNumber).set({
      OrderNumber: uniqueNumber,
      CorporationName: getSelectedCustAllData.CorporationName,
      BusinessDBA: getSelectedCustAllData.BusinessDBA,
      PhoneNumber: getSelectedCustAllData.PhoneNumber,
      Email: getSelectedCustAllData.Email,
      OwnerName: getSelectedCustAllData.OwnerName,
      Address: getSelectedCustAllData.Address,
      City: getSelectedCustAllData.City,
      State: getSelectedCustAllData.State,
      Zip: getSelectedCustAllData.Zip,
      orderedProducts: invoiceData,
      totalCost: orderTotal,
      invoiceDate: month + "/" + date + "/" + year,
    });
    setShowNewOrderForm(false);
  };
  //console.log(orderHistory)

  const handleClose = () => {
    setOpenDialogOrderDetails(false);
  };

  return (
    <>
      <br />      <br />      <br />      <br />
      <Container className="mt--6" fluid>
        <Card>
          {showNewOrderForm === true ? (
            <>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Enter New Order Details</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      onClick={() => {
                        setShowNewOrderForm(false);
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleOrderForm}>
                  <Row form>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h2">Customer Information</CardTitle>
                          <Autocomplete
                            id="combo-box-demo"
                            options={searchCustomerID}
                            getOptionLabel={(option) => option}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Customer"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, newValue) => {
                              //select customer from drop down list and get all the information
                              if (newValue != null) {
                                allCustomerDetails.map((a) => {
                                  if (newValue === a.Email) {

                                    setGetSelectedCustAllData(a);
                                  }
                                  return a;
                                });
                              }
                            }}
                          />
                        </CardHeader>
                        <CardBody>
                          {getSelectedCustAllData !== null ? (
                            <>
                              <b>Corporation Name:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.CorporationName}
                              </Label>
                              <br />
                              <b>DBA:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.BusinessDBA}
                              </Label>
                              <br />
                              <b>Phone:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.PhoneNumber}
                              </Label>
                              <br />
                              <b>E-mail:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.Email}
                              </Label>
                              <br />
                              <b>Application Type:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.ApplicationType}
                              </Label>
                              <br />
                              <b>Owner Name:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.OwnerName}
                              </Label>
                              <br />
                              <b>Service Address:</b>{" "}
                              <Label for="permnitno">
                                {getSelectedCustAllData.Address},{' '}
                                {getSelectedCustAllData.City},{' '}
                                {getSelectedCustAllData.State},{' '}
                                {getSelectedCustAllData.Zip}
                              </Label>
                              <br />
                            </>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h2">
                            Order Short Information
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          {getSelectedCustAllData !== null ? (
                            <>
                              <Label style={{ backgroundColor: "#FFFD77" }}>
                                <b>Total Cost: </b>
                                <NumberFormat
                                  value={orderTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </Label>
                              <br />
                            </>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <MaterialTable
                        icons={tableIcons}
                        title="Order List"
                        columns={columnsCreateOrder}
                        data={invoiceData}
                        options={{
                          exportButton: true,
                          headerStyle: {
                            backgroundColor: "#8BCCFE",
                            color: "#000000",
                            textColor: "#000000",
                          },
                        }}
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                newData["totalcost"] =
                                  newData["quantity"] * newData["costperunit"];
                                setInvoiceData([...invoiceData, newData]);
                                resolve();
                              }, 1000);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                const dataUpdate = [...invoiceData];
                                const index = oldData.tableData.id;
                                newData["totalcost"] =
                                  newData["quantity"] * newData["costperunit"];
                                dataUpdate[index] = newData;
                                setInvoiceData([...dataUpdate]);
                                resolve();
                              }, 1000);
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                const dataDelete = [...invoiceData];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setInvoiceData([...dataDelete]);
                                resolve();
                              }, 1000);
                            }),
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Button
                    classes={{
                      root: classes.root, // class name, e.g. `classes-nesting-root-x`
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </>
          ) : (
            <>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col text-right">
                    <Button
                      classes={{
                        root: classes.root, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                      }}
                      onClick={() => setShowNewOrderForm(true)}
                    >
                      Create New Order
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {orderHistory !== null ? (
                  <>
                    <MaterialTable
                      icons={tableIcons}
                      title="Order History"
                      columns={columnsOrderHistory}
                      data={orderHistory}
                      actions={[
                        {
                          icon: MoreVertIcon,
                          tooltip: "Detailed Information",
                          onClick: (event, rowData) => {
                            setOpenDialogOrderDetails(true);
                            setSelectedRowData(rowData);
                          },
                        },
                      ]}
                      options={{
                        exportButton: true,
                        headerStyle: {
                          backgroundColor: "#8BCCFE",
                          color: "#000000",
                          textColor: "#000000",
                        },
                      }}
                    />
                  </>
                ) : null}
              </CardBody>
              {orderHistory !== null ? (
                <>
                  <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={openDialogOrderDetails}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle
                      id="max-width-dialog-title"
                      onClose={handleClose}
                    >
                      Order Details #{selectedRowData.OrderNumber}
                    </DialogTitle>
                    <DialogContent>
                      <Row form>
                        <Col md={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h2">Bill From</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {selectedRowData !== null ? (
                                <>
                                  <Label for="permnitno">YD Techs</Label>
                                  <br />
                                  <Label for="permnitno">8005 John Ralston Rd</Label>
                                  <br />
                                  <Label for="permnitno">Houston, TX 77044</Label>
                                  <br />
                                  <Label for="permnitno">+1-713-597-1994</Label>
                                  <br />
                                  <Label for="permnitno">anish@ydtechs.com</Label>
                                  <br />
                                </>
                              ) : null}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h2">Ship To</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {selectedRowData !== null ? (
                                <>
                                  <Label for="permnitno">
                                    {selectedRowData.CorporationName}{' DBA '}{selectedRowData.BusinessDBA}
                                  </Label>
                                  <br />
                                  <Label for="permnitno">
                                    {selectedRowData.OwnerName}
                                  </Label>
                                  <br />
                                  <Label for="permnitno">
                                    {selectedRowData.Address},{' '}<br />
                                    {selectedRowData.City},{' '}
                                    {selectedRowData.State},{' '}
                                    {selectedRowData.Zip}
                                  </Label>
                                  <br />
                                  <Label for="permnitno">
                                    {selectedRowData.PhoneNumber}
                                  </Label>
                                  <br />
                                  <Label for="permnitno">
                                    {selectedRowData.Email}
                                  </Label>
                                  <br />                                                                   
                                </>
                              ) : null}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={12}>
                          <MaterialTable
                            icons={tableIcons}
                            title="Order List"
                            columns={columnsCreateOrder}
                            data={selectedRowData.orderedProducts}
                            options={{
                              exportButton: true,
                              headerStyle: {
                                backgroundColor: "#8BCCFE",
                                color: "#000000",
                                textColor: "#000000",
                              },
                            }}
                          />
                        </Col>
                      </Row>{" "}
                      <br />
                      <Row form>
                        <Col md={6}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h2">Order Information</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {selectedRowData !== null ? (
                                <>
                                  <Label style={{ backgroundColor: "#FFFD77" }}>
                                    <b>Total Cost: </b>
                                    <NumberFormat
                                      value={selectedRowData.totalCost}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </Label>
                                  <br />                                 
                                </>
                              ) : null}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <CardBody>
                              <CardTitle tag="h4">
                                {" "}
                                Ordered Status
                              </CardTitle>
                              <br />
                              __________________________
                            </CardBody>
                          </Card>                          
                        </Col>
                      </Row>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        classes={{
                          root: classes.root, // class name, e.g. `classes-nesting-root-x`
                          label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
export default Orders;

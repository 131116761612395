export var AnishStyles = {
    navLink: {
        color: '#000000',
    },
    navLinkFooter: {
        color: '#ffffff',
    },
    backgroundIndex:{
        color:'#F4FFFDFF'
    },
    navItemStyle:{
        paddingTop:0
    },
    LinkDropDownBG: {
        width: '100px',
        color:'#fd45dd'
    }
};
export default AnishStyles;
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import { databaseOrder } from "./CustomComponent/BackendDataConfig"
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import "firebase/database";
import NumberFormat from 'react-number-format';

function Dashboard() {
  const [yearlySale, setYearlySale] = React.useState(0);
  const [monthlySale, setMonthlySale] = React.useState(0);
  const [dailySale, setDailySale] = React.useState(0);

  useEffect(() => {
    const dbRef = firebase.database().ref(databaseOrder);

    dbRef.get().then((snapshot) => {
      let users = [], salesYearly = [], saleMonthly = [], saleDaily = []
      var todayDate = new Date();
      if (snapshot.exists()) {
        snapshot.forEach(snap => {
          users.push(snap.val())
          var getInvoiceDate = new Date(snap.val().invoiceDate);
          if (getInvoiceDate.getFullYear() === todayDate.getFullYear()) {
            salesYearly.push({
              total: snap.val().finalTotalAfterDiscount,
              invoiceDate: snap.val().invoiceDate,
              tabcStickerUsed: snap.val().tabcStickerUsed
            })
          }
          if (getInvoiceDate.getMonth() === todayDate.getMonth()) {
            saleMonthly.push({
              total: snap.val().finalTotalAfterDiscount,
              invoiceDate: snap.val().invoiceDate,
              tabcStickerUsed: snap.val().tabcStickerUsed
            })
          }
          if (getInvoiceDate.getDate() === todayDate.getDate()) {
            saleDaily.push({
              total: snap.val().finalTotalAfterDiscount,
              invoiceDate: snap.val().invoiceDate,
              tabcStickerUsed: snap.val().tabcStickerUsed,
            })
          }
        })
        // get yearly sale calcualtion 
        setYearlySale(salesYearly.reduce((a, b) => a + b.total, 0))
        //get monthly sale calculation
        setMonthlySale(saleMonthly.reduce((a, b) => a + b.total, 0))
        //get monthly sale calculation
        setDailySale(saleDaily.reduce((a, b) => a + b.total, 0))
        // console.log(users)
        //console.log(salesYearly)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);
  return (
    <>
  <br/><br/><br/><br/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Yearly</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Label><b>Sale: </b>
                  <NumberFormat value={yearlySale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Label><br />
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Monthly</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Label><b>Sale: </b>
                  <NumberFormat value={monthlySale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Label><br />
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h3 mb-0">Daily</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Label><b>Sale: </b>
                  <NumberFormat value={dailySale} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Label><br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, Nav, Col, Container, Row } from "reactstrap";
import {
  Box,
  Container1,
  Row1,
  Column,
  FooterLink,
  Heading,
  Address
} from "./FooterStyles";
import AnishStyles from "styles/AnishDesignCSS";

function AuthFooter() {
  return (
    <>

      <Box className="py-5" id="footer-main">
        <Container1>
          <Row1>
            <Column>
              <Heading>Links</Heading>
              <FooterLink href="https://ydtechs.com/about-us/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>About Us</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/portfolio/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Portfolio</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/case-studies/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Case Studies</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/blog/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Blog</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/team-members/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Our Team</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/contact-us/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Contact Us</span>
              </FooterLink>
            </Column>
            <Column>
              <Heading> Services</Heading>
              <FooterLink href="https://ydtechs.com/web-design/">
                  <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Web Design</span>
                </FooterLink>
                <FooterLink href="https://ydtechs.com/mobile-app/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Mobile App</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/custom-software/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Custom Software</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/e-commerce/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>E-Commerce</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/booking-website/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>Booking Website</span>
              </FooterLink>
              <FooterLink href="https://ydtechs.com/it-consultant/">
                <span className="nav-link-inner--text" style={AnishStyles.navLinkFooter}>IT Consultant</span>
              </FooterLink>
            </Column>

            <Column>
              <Heading>Contact Us</Heading>
              <Address style={{ fontSize: "18px", fontWeight: 'bold' }}>YD Techs</Address>
              <Address >8005 John Ralston Rd,</Address>
              <Address style={{ color: "white" }}>Houston TX 77044</Address>
              <Address style={{ color: "white" }}>+1-713-597-1994</Address>
              <Address style={{ color: "white" }}>anish@ydtechs.com</Address>
              <Address style={{ color: "white" }}>jiten@ydtechs.com</Address>
            </Column>
            <Column>
              <Heading>Social Media</Heading>
              <FooterLink href="#">
                <i className="fab fa-facebook-f">
                  <span style={{ marginLeft: "10px" }}>
                    Facebook
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="#">
                <i className="fab fa-instagram">
                  <span style={{ marginLeft: "10px" }}>
                    Instagram
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="#">
                <i className="fab fa-twitter">
                  <span style={{ marginLeft: "10px" }}>
                    Twitter
                  </span>
                </i>
              </FooterLink>
              <FooterLink href="#">
                <i className="fab fa-youtube">
                  <span style={{ marginLeft: "10px" }}>
                    Youtube
                  </span>
                </i>
              </FooterLink>
            </Column>
          </Row1>
        </Container1>
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.ydtechs.com"
                  target="_blank"
                >
                  YD Techs
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                <FooterLink href="https://ydtechs.com/terms-of-service/">
                    <span className="nav-link-inner--text">Terms of Service</span>
                  </FooterLink>
                </NavItem>
                &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;
                <NavItem>
                <FooterLink href="https://ydtechs.com/privacy-policy/">
                    <span className="nav-link-inner--text">Privacy Policy</span>
                  </FooterLink>
                </NavItem>
                
              </Nav>
            </Col>
          </Row>
        </Container>
      </Box>
    </>
  );
}

export default AuthFooter;
